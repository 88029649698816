import { Device } from "hybrids-socket";

// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.


export const environment = {
  production: false,
  device: Device.STUDENT,
  version: '3.0',
  defaultLanguage: 'es',
  // endPointSocket: 'ws://localhost:3000/ws'
  // endPointSocket: 'ws://192.168.200.10:3000/ws'
  endPointSocket: 'wss://hvitawebsocket.dev.idimedical.com/ws'
  // endPointSocket: 'wss://hvita.socket.rel.idimedical.com/ws'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
